import React from 'react';
import { MdNoEncryption } from 'react-icons/md';
import DotLoader from 'react-spinners/DotLoader';
import PasswordInputControl from "./PasswordInputControl";
import { CountryDropdown } from 'react-country-region-selector';

class CreateAccountForm extends React.Component {
    constructor(props) {
        super(props);

        let server = this.props.server,
            token = this.props.token;

        this.state = {
            affiliateInfoUrl: server + 'v1/affiliate/info',
            affiliateSignUpUrl: server + 'v1/affiliate/referral/signup',
            token,
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            country : '',
            request: 'PREFETCH',
            sender: '',
            isAffiliateInfoLoaded: false,
            errorMessage: '',
        };
    }

    componentDidMount() {
        this.getAffiliateInfo();
    }

    _onChangeFirstName = (event) => this.setState({ firstName: event.target.value });

    _onChangeLastName = (event) => this.setState({ lastName: event.target.value });

    _onChangeEmail = (event) => this.setState({ email: event.target.value });

    _onChangePassword = (event) => this.setState({ password: event.target.value });

    _selectCountry = (val) => this.setState({ country : val });

    _onSubmit = (e) => {
        e.preventDefault();
        const { firstName, lastName, email, password, countryCode } = this.state;

        var pattern = new RegExp(
            "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[-+_!@#$%^&*.,?]).+$"
        );

        if ( firstName === '' || lastName === '' || 
            email === '' || password === '' ) {
            this.setState({ request: 'ERROR_NOT_SPECIFIED' })
        } else if ( !pattern.test(password)) {
            this.setState({ request: "INVALID_PASSWORD" })
        } else {
            this.signUp({
                firstName,
                lastName,
                email,
                password,
                countryCode: countryCode,
            });
        }
    }

    getAffiliateInfo = () => {
        const { token, affiliateInfoUrl } = this.state;

        let version = new Date();
        let lurl = affiliateInfoUrl;
        lurl += '?version=' + version;

        fetch(lurl, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                user: { affiliateCode : token }
            })
        })
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success === 'OK') {
                    if (result.code === 'TOKEN_EXPIRED') {
                        this.setState({
                            isAffiliateInfoLoaded: true,
                            request: "TOKEN_EXPIRED"
                        })
                    } else {
                        let response = result.message
                        this.setState({
                            isAffiliateInfoLoaded: true,
                            countryCode: response.countryCode,
                            sender: response.fullName,
                            brand: response.brand,
                            trialDays: response.trialPeriod,
                            percentageDiscount: response.percentageDiscount,
                        })
                    }
                } else {
                    this.setState({
                        isAffiliateInfoLoaded: true,
                        request: "SERVER_ERROR",
                        errorMessage: result.message
                    })
                }
            },

            (error) => {
                console.log(error);
                this.setState({
                    isAffiliateInfoLoaded: true,
                    request: "SERVER_ERROR",
                    errorMessage: error || ''
                })
            },
        )
    }

    signUp(address) {
        const { affiliateSignUpUrl, token } = this.state;

        let version = new Date();
        let lurl = affiliateSignUpUrl;
        lurl += '?version=' + version;

        fetch(lurl, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                user: {
                    affiliateCode: token,
                    firstName: address.firstName,
                    lastName: address.lastName,
                    email : address.email,
                    password: address.password,
                    countryCode: address.countryCode,
                }
            })
        })
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.success === 'OK') {
                        if (result.code === 'TOKEN_EXPIRED') {
                            this.setState({ request: "TOKEN_EXPIRED" })
                        } else {
                            this.setState({ request: "EMAIL_VERIFICATION" })
                        }
                    } else {
                        this.setState({ 
                            request: "SERVER_ERROR",
                            errorMessage: result.message
                         })
                    }
                },

                (error) => {
                    console.log(error);
                    this.setState({ 
                        request: "SERVER_ERROR",
                        errorMessage: error || ''
                     })
                },
            )
    }

    render() {

        const { request, firstName, lastName, token, password, email, isAffiliateInfoLoaded, countryCode, sender, trialDays, brand, percentageDiscount, errorMessage } = this.state;

        if (!isAffiliateInfoLoaded) {
            return (
                <div className="container">
                    <div className="innerSpacer spinnerCenter">
                        <DotLoader color='#008AFF' loading={true}/> 
                    </div>
                </div>
            )
        }  else if (request === 'TOKEN_EXPIRED') {
            return (
                <div className="container">
                <div className="wrap innerSpacer center">
                    <h5 className="header3 center">Invalid link</h5>
                    <MdNoEncryption size="10em" color='#008AFF' />
                </div>
                <p className="token-expired-description center">This affiliate link is invalid. Please contact your affiliate.</p>
            </div>
            )
        } else if (request === 'EMAIL_VERIFICATION') {
            return (
                <div className="container">
                    <form className="pure-form address-form">
                        <div className="spacer center">
                        <h3 className="header3-left">Verify your email</h3>
                            <p className="description left">
                                We need you to confirm your email so we know it’s you. It should be in your inbox shortly.
                                <br></br>
                                <br></br>  
                                Click the link in the email to continue.
                            </p>
                        </div>
                    </form>                 
                </div>
            )
        } else {
            return (
                <div className="container">
                    <form className="pure-form address-form">
                        <div className="spacer">
                            <h3 className="header3-left">Create an account</h3>
                            <p className="description left">
                                {brand ? (
                                    <>
                                        <span className="bold">{brand.charAt(0).toUpperCase() + brand.slice(1)}</span> has partnered with Uome to provide you with the tools you need to supercharge your business - exclusively with a <strong>{percentageDiscount * 100}% discount</strong>
                                    </>
                                ) : (
                                    <>
                                        <span className="bold">{sender}</span> has partnered with Uome to provide you with the tools you need to supercharge your business, all with a <strong>{trialDays}-day</strong> free trial.
                                    </>
                                )}
                            </p>
                            <div className="input-control-row">
                                <div className="input-control-wrapper">
                                    <input
                                        type="firstName"
                                        className="input-control"
                                        value={firstName}
                                        placeholder="First name*"
                                        style={{textTransform:"capitalize"}}
                                        maxLength={70}
                                        onChange={this._onChangeFirstName}
                                    />
                                </div>
                                <div className="input-control-wrapper">
                                    <input
                                        type="lastName"
                                        className="input-control"
                                        value={lastName}
                                        placeholder="Last name*"
                                        style={{textTransform:"capitalize"}}
                                        maxLength={70}
                                        onChange={this._onChangeLastName}
                                    />
                                </div>
                            </div>
                            <div className="input-control-wrapper">
                                <input
                                    type="email"
                                    id="email"
                                    className="input-control"
                                    value={email}
                                    placeholder="Email"
                                    onChange={this._onChangeEmail}
                                />
                            </div>
                            <div>
                                <PasswordInputControl
                                    wrapperClasses="input-control-wrapper password"
                                    placeholder="Password"
                                    value={password}
                                    onChange={this._onChangePassword}
                                />
                            </div>
                            <div className="input-control-wrapper">
                                <CountryDropdown
                                    value={countryCode}
                                    className="input-control"
                                    valueType='short'
                                    priorityOptions={["GB", "US"]}
                                    whitelist={["GB", "US"]}
                                    onChange={(val) => this._selectCountry(val)}
                                    disabled={true} />
                            </div>
                            {!brand && (
                                <div className="input-control-wrapper">
                                    <input
                                        type="text"
                                        id="Affiliate"
                                        className="input-control-bold"
                                        defaultValue={token}
                                        placeholder="Affiliate code"
                                        readOnly={true}
                                    />
                                    <label className="affiliateCode" htmlFor="Affiliate">Affiliate code</label>
                                </div>
                            )}

                            <p className="description-grey left" style={{ fontSize: '13px' }}>
                                {brand ? (
                                    <>Please note that you will be asked to provide your card details when subscribing. In order to be eligible for this offer, you must present a valid card from {brand.charAt(0).toUpperCase() + brand.slice(1)}.</>
                                ) : (
                                    <>
                                        Please note that you will be asked to provide your card details when subscribing. No payment will be taken until the end of your free trial. You can manage or cancel your subscription at any time.
                                    </>
                                )}
                            </p>
                            {request === "ERROR_NOT_SPECIFIED" &&
                                <p className="errorMessage left">Please supply all mandatory fields e.g. first name, last name, email and password</p>
                            }
                            {request === "INVALID_PASSWORD" &&
                                <p className="errorMessage left">Please enter a strong password.<br/>
                                Must have 8 characters minimum with at least 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character.</p>
                            }
                            {request === "SERVER_ERROR" && errorMessage === '' &&
                                <p className="errorMessage left">Something went wrong, please try again.</p>
                            }
                            {request === "SERVER_ERROR" && errorMessage !== '' &&
                                <p className="errorMessage left">{errorMessage}</p>
                            }
                        </div>
                        <div className="input-control-submit-wrapper">
                            <button type="button" className="button-full-width" onClick={this._onSubmit}>Continue</button>
                        </div>
                    </form>                    
                </div>
            )
        }
    }
}

export default CreateAccountForm;