import React from 'react';
import { MdPanoramaFishEye, MdCheckCircle, MdInfo, MdNoEncryption } from 'react-icons/md';
import AffiliatePaymentInfo from './AffiliatePaymentInfo';
import DotLoader from 'react-spinners/DotLoader';
import { getCurrencySymbol } from '../utils/currencyMapper';

class YourPlanForm extends React.Component {
  constructor(props) {
    super(props);
    let server = this.props.server,
        stripeKey = this.props.stripeKey,
        token = this.props.token;

    this.state = {
      validateEmailUrl: server + 'v1/affiliate/referral/validate',
      affiliateInfoUrl: server + 'v1/affiliate/info',
      token,
      isMonthly: false,
      isYearly: false,
      isReadyForPayment: false,
      isEmailValidated: false,
      server,
      stripeKey,
      request: '',
      errorMessage: null,
      countryCode: null,
      products: [],
      brand: null,
      percentageDiscount: null,
    };
  }

  componentDidMount() {
    this.validateEmail();
  }

  validateEmail = () => {
    const { token, validateEmailUrl } = this.state;

    let version = new Date();
    let lurl = `${validateEmailUrl}?version=${version}`;

    fetch(lurl, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ user: { token } })
    })
    .then(res => res.json())
    .then(
      (result) => {
        if (result.success === 'OK') {
          if (result.code === 'TOKEN_EXPIRED') {
            this.setState({
              isEmailValidated: true,
              request: "TOKEN_EXPIRED"
            });
          } else {
            this.setState({
              isEmailValidated: true,
              affiliateCode: result.message.affiliateCode
            }, () => {
              this.getAffiliateInfo();
            });
          }
        } else {
          this.setState({
            isEmailValidated: true,
            request: "SERVER_ERROR",
            errorMessage: result.message
          });
        }
      },
      (error) => {
        console.log(error);
        this.setState({
          isEmailValidated: true,
          request: "SERVER_ERROR",
          errorMessage: error || null
        });
      }
    );
  }

  getAffiliateInfo = () => {
    const { affiliateCode, affiliateInfoUrl } = this.state;

    let version = new Date();
    let lurl = `${affiliateInfoUrl}?version=${version}`;

    fetch(lurl, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ user: { affiliateCode } })
    })
    .then(res => res.json())
    .then(
      (result) => {
        if (result.success === 'OK') {
          if (result.code === 'TOKEN_EXPIRED') {
            this.setState({
              isAffiliateInfoLoaded: true,
              request: "TOKEN_EXPIRED"
            });
          } else {
            let response = result.message;
            this.setState({
              isAffiliateInfoLoaded: true,
              countryCode: response.countryCode,
              sender: response.fullName,
              brand: response.brand,
              trialDays: response.trialPeriod,
              percentageDiscount: response.percentageDiscount,
              products: response.products
            }, () => {
              // Set initial plan based on brand presence
              if (this.state.brand) {
                this.setState({ isMonthly: true, isYearly: false });
              }
            });
          }
        } else {
          this.setState({
            isAffiliateInfoLoaded: true,
            request: "SERVER_ERROR",
            errorMessage: result.message
          });
        }
      },
      (error) => {
        console.log(error);
        this.setState({
          isAffiliateInfoLoaded: true,
          request: "SERVER_ERROR",
          errorMessage: error || null
        });
      }
    );
  }

  _onMonthlyClick = (e) => {
    this.setState({
      isMonthly: !this.state.isMonthly,
      isYearly: this.state.isMonthly ? false : this.state.isYearly
    });
  }

  _onAnnualClick = (e) => {
    this.setState({
      isYearly: !this.state.isYearly,
      isMonthly: this.state.isYearly ? false : this.state.isMonthly
    });
  }

  _onSubmit = (e) => {
    const { isMonthly, isYearly } = this.state;

    if (!isMonthly && !isYearly) {
      this.setState({ request: "ERROR_NOT_SPECIFIED" });
    } else {
      this.setState({ isReadyForPayment: true });
    }
  }

  render() {
    const { isMonthly, isYearly, isReadyForPayment, isEmailValidated, 
            request, errorMessage, products, brand, percentageDiscount, countryCode } = this.state;

    // Find product details based on the selected plan
    const monthlyProduct = products.find(p => p.productId === 'simple_monthly');
    const annualProduct = products.find(p => p.productId === 'simple_annual');
    const currencySymbol = getCurrencySymbol(countryCode);

    const planDetails = isYearly
  ? {
      description: annualProduct?.name,
      price: `${currencySymbol}${(annualProduct?.amount / 100).toFixed(2)}`,
      postfix: ' /yearly',
      brand: brand || null,
      discountedPrice: `${currencySymbol}${(annualProduct?.discountedAmount / 100).toFixed(2)}` || null
    }
  : {
      description: monthlyProduct?.name,
      price: `${currencySymbol}${(monthlyProduct?.amount / 100).toFixed(2)}`,
      postfix: ' /monthly',
      brand: brand || null,
      discountedPrice: `${currencySymbol}${(monthlyProduct?.discountedAmount / 100).toFixed(2)}` || null
    };

    if (!isEmailValidated) {
      return (
        <div className="container">
          <div className="innerSpacer spinnerCenter">
            <DotLoader color='#008AFF' loading={true}/> 
          </div>
        </div>
      );
    } else if (request === 'SERVER_ERROR') {
      return (
        <div className="container">
          <div className="wrap innerSpacer center">
            <h5 className="header3 center">Something went wrong</h5>
            <MdInfo size="10em" color='#FF5252' />
          </div>
          {errorMessage !== "" ?
            <p className="something-wrong-description center">{errorMessage}</p>
            :
            <p className="something-wrong-description center">Sorry about that, please try again.</p>}
        </div>
      );
    } else if (request === 'TOKEN_EXPIRED') {
      return (
        <div className="container">
          <div className="wrap innerSpacer center">
            <h5 className="header3 center">Link expired</h5>
            <MdNoEncryption size="10em" color='#008AFF' />
          </div>
          <p className="token-expired-description center">This validate email link has expired, a new one has been sent to your email.</p>
          <p className="token-expired-description center">Please check your inbox and click on the link.</p>
        </div>
      );
    } else if (isReadyForPayment) {
      const plan = isYearly ? 'annual' : 'monthly';
      return (
        <AffiliatePaymentInfo
          server={this.state.server}
          plan={plan} 
          token={this.state.token}
          stripeKey={this.state.stripeKey}
          planDetails={planDetails}
        />
      );
      
    } else {
      return (
        <div className="container">
          <form className="pure-form address-form">
            <h3 className="header3-left">Your plan</h3>
            <p className="description left">
              {brand 
                ? <>Exclusive <strong>{percentageDiscount * 100}% discount</strong> for the {brand.charAt(0).toUpperCase() + brand.slice(1)} community. Get the tools you need to run your business.</>
                : "Select the right plan for you. Get the tools you need to run your business. Choose from one of the options below."
              }
            </p>
            <div>
              <div className='grey'>
                <div>
                <h4 className="button-header">{monthlyProduct?.name}</h4>
                {brand ? (
                  <span className="button-price">
                    <span className="original-price">
                      {currencySymbol}{(monthlyProduct?.amount / 100).toFixed(2)}
                    </span>
                    <span className="discounted-price">
                      {currencySymbol}{(monthlyProduct?.discountedAmount / 100).toFixed(2)}
                    </span>
                    <span className="price-label"> /monthly</span>
                  </span>
                ) : (
                  <span className="button-price"> 
                    <span className="bold">
                      {currencySymbol}{(monthlyProduct?.amount / 100).toFixed(2)}&nbsp;
                    </span>
                    /monthly
                  </span>
                )}
                <button
                  className={`manage_plan_buttons ${brand ? 'offer-margin' : ''}`}
                  onClick={this._onMonthlyClick}
                  type="button"
                >
                  {isMonthly 
                    ? <MdCheckCircle size="2em" color='#008AFF'/> 
                    : <MdPanoramaFishEye size="2em" color='#A1A5AC'/>
                  }
                </button>
              </div>
              </div>
              { !brand && (
                <>
                  <div className='affiliateSpacer'></div>
                  <div className='affiliateSpacer'></div>
                  <div className='grey'>
                    <h4 className="button-header">{annualProduct?.name}</h4>
                    <span className="button-price"> 
                      <span className="bold">{currencySymbol}{(annualProduct?.amount / 100).toFixed(2)}&nbsp;</span>
                      /yearly
                    </span>
                    <button
                      className="manage_plan_buttons"
                      onClick={this._onAnnualClick}
                      type="button"
                    >
                      {isYearly 
                        ? <MdCheckCircle size="2em" color='#008AFF' /> 
                        : <MdPanoramaFishEye size="2em" color='#A1A5AC' />
                      }
                    </button>
                  </div>
                </>
              )}
              <div className='affiliateSpacer'></div>
              <div className='affiliateSpacer'></div>
              <p className="description-grey left"> 
                The complete stack for running a business. Accept in-person payments, manage your clients, track expenses, organise your products, online shopfront...&nbsp;
                <a href="https://www.myuome.com/pricing" target="_blank" rel="noopener noreferrer">Learn more</a>
              </p>
              {request === "ERROR_NOT_SPECIFIED" &&
                <p className="errorMessage left">Please select a plan</p>
              }
              <div className='affiliateSpacer'></div>
              <div className="input-control-submit-wrapper">
                <button type="button" className="button-full-width" onClick={this._onSubmit}>Continue</button>
              </div>
            </div>
          </form>
        </div>
      );
    }
  }
}

export default YourPlanForm;
