import React from 'react';
import AppStoreIcon from "../images/btn-app-store.png";
import PlayStoreIcon from "../images/btn-google-play.png";
import { MdNoEncryption } from 'react-icons/md';
import DotLoader from 'react-spinners/DotLoader';
import { loadStripe } from '@stripe/stripe-js';
import { CardElement, ElementsConsumer, Elements } from '@stripe/react-stripe-js';

class CheckoutForm extends React.Component {
  constructor(props) {
    super(props);

    const { server, plan, token, planDetails }  = this.props;
        
    this.state = {
        subscribeUrl: server + 'v1/stripe-web-payment/affiliate/referral/subscribe',
        settle3DPaymentUrl: server + 'v1/stripe-web-payment/affiliate/referral/3d-payment',
        token,
        request: '',
        plan,
        description: planDetails.description,
        price: planDetails.price,
        postfix: planDetails.postfix,
        brand: planDetails.brand,
        discountedPrice: planDetails.discountedPrice,
        isProcessing: false,
        errorMessage: '',
    };
  }

    _onSubmit = (event) => {
        event.preventDefault();
        this.getPaymentmethod();
    }


    getPaymentmethod = async () => {
        const { stripe, elements } = this.props;

        try {
            stripe.createPaymentMethod({
                type: 'card',
                card: elements.getElement(CardElement),
            })
            .then((result) => {
                    if ( result.paymentMethod ) {
                        this.setState({ isProcessing : true });
                        this.subscribe(result.paymentMethod.id)
                    } else {
                        // alert("error-if")
                        this.setState({ 
                            request: "SERVER_ERROR",
                            isProcessing: false
                        })    
                    }
                },
                (error) => {
                    // alert(error);
                    this.setState({ 
                        request: "SERVER_ERROR",
                        isProcessing: false
                    })
                },
            )
        } catch(e) {
            // alert("try-catch")
            this.setState({ 
                request: "SERVER_ERROR",
                isProcessing: false
            })
        }
    }

    subscribe = (paymentMethod) => {
        const { subscribeUrl, plan, token } = this.state;

        let version = new Date();
        let lurl = subscribeUrl;
        lurl += '?version=' + version;

        fetch(lurl, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                user: {
                    token,
                    paymentMethod,
                    plan: plan === 'annual' ? 'simple_annual' : 'simple_monthly',
                }
            })
        })
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success === 'OK') {
                    if (result.code === 'TOKEN_EXPIRED') {
                        this.setState({
                            isProcessing: false, 
                            request: "TOKEN_EXPIRED" 
                        })
                    } else {
                        const response = result.message

                        if ( response.requires_action && 
                             response.payment_intent_client_secret ) {                    
                            this.confirmCardPayment(response.payment_intent_client_secret);
                        } else {
                            this.setState({
                                isProcessing: false,
                                request: "DOWNLOADS" 
                            })
                        }                      
                    }
                } else { 
                    const err = result.message; 
                    const errMsg = err.payment_intent_status === 'requires_payment_method' ? 
                                    "Invalid card, please use a different card" : null
                    this.setState({
                        isProcessing: false, 
                        request: "SERVER_ERROR",
                        errorMessage: err.decline_code && err.code ?
                                      `${err.decline_code} : ${err.code}` :
                                      err || err.code || errMsg || ''
                    })
                }
            },

            (error) => {
                console.log(error);
                this.setState({ 
                    isProcessing: false,
                    request: "SERVER_ERROR",
                })
            },
        )
    }

    confirmCardPayment = ( clientSecret ) => {

        const { stripe } = this.props;

        stripe.confirmCardPayment(clientSecret).then((result) => {
            if ( result.paymentIntent ) {
                this.setState({ isProcessing : true });
                this.settle3DPayment();
            } else if ( result.error ) {
                const err = result.error; 
                this.setState({ 
                    request: "SERVER_ERROR",
                    isProcessing: false,
                    errorMessage: err.decline_code && err.code ?
                                      `${err.decline_code} : ${err.code}` :
                                      err.code || ''
                })
            } else {
                this.setState({ 
                    request: "SERVER_ERROR",
                    isProcessing: false
                })    
            }
        },
        (error) => {
            // alert(error);
            this.setState({ 
                request: "SERVER_ERROR",
                isProcessing: false,
                errorMessage: error.decline_code && error.code ? 
                                `${error.decline_code} : ${error.code}` :
                                error.code || '' 
            })
        },
        )
    }

    settle3DPayment = () => {
        const { settle3DPaymentUrl, token } = this.state;

        let version = new Date();
        let lurl = settle3DPaymentUrl;
        lurl += '?version=' + version;

        fetch(lurl, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                user: {
                    token,
                }
            })
        })
        .then(res => res.json())
        .then(
            (result) => {
                if (result.success === 'OK') {
                    if (result.code === 'TOKEN_EXPIRED') {
                        this.setState({
                            isProcessing: false, 
                            request: "TOKEN_EXPIRED" 
                        })
                    } else {
                        this.setState({
                            isProcessing: false,
                            request: "DOWNLOADS" 
                        })                      
                    }
                } else {
                    this.setState({
                        isProcessing: false, 
                        request: "SERVER_ERROR",
                        errorMessage: result.message
                    })
                }
            },

            (error) => {
                console.log(error);
                this.setState({ 
                    isProcessing: false,
                    request: "SERVER_ERROR",
                })
            },
        )
    }  
    
    render() {
        const { description, price, discountedPrice, postfix, brand, request, isProcessing, errorMessage } = this.state;

        if (isProcessing) {
            return (
                <div className="container">
                    <div className="innerSpacer spinnerCenter">
                        <DotLoader color='#008AFF' loading={true}/> 
                    </div>
                </div>
            )
        } else if (request === 'DOWNLOADS') {
            return (
                <div className="container">
                    <form className="address address-form">
                        <div className="downloadSpacer">
                            <h3 className="header3-left">You’re subscribed</h3>
                                <p className="description left"> 
                                    Download the Uome app and login using the details you provided.
                                </p>
                                <a className="appIconSpacer" href="https://apps.apple.com/gb/app/uome/id1608161556" target="_blank" rel="noopener noreferrer">
                                    <img src={AppStoreIcon} alt="AppStore" width="160"/>
                                </a>
                                &nbsp;
                                <a href="https://play.google.com/store/apps/details?id=com.myuome.app" target="_blank" rel="noopener noreferrer">
                                    <img src={PlayStoreIcon} alt="PlayStore" width="160"/>
                                </a>
                        </div>
                    </form>
                </div>
            )
        } else if (request === 'TOKEN_EXPIRED') {
            return (
                <div className="container">
                    <div className="innerSpacer center">
                        <div className="wrap innerSpacer center">
                            <MdNoEncryption size="16em" color='#008AFF' />
                        </div>
                        <h6 className="header4">This validate email link has <br />
                            expired, a new one has <br /> been sent to your email.</h6>
                        <h6 className="header5">Please check your inbox <br />
                            and click on the link.</h6>
                    </div>
                </div>
            )
        } else {
            return (
            <div className="container">
                <form className="pure-form address-form">
                <h3 className="header3-left">Payment info</h3>
                    <p className="description left"> 
                    End-to-end encrypted payment. Manage your account easily online.
                    </p>
                <div>
                    <div className="grey">
                        <h4 className="button-header">{description}</h4> 
                        {brand ? (
                            <span className="button-price">
                                <span className="original-price">
                                {price}
                                </span>
                                <span className="discounted-price">
                                {discountedPrice}
                                </span>
                                <span className="price-label">{postfix}
                                </span>
                            </span>
                            ) : (
                            <span className="button-price"> 
                                <span className="bold">
                                {price}
                                </span>
                                {postfix}
                                </span>
                            )}
                                              

                        <span className={`change-price ${brand ? 'offer-margin' : ''}`}><a href=" ">change</a></span>
                    </div>
                    <div className='affiliateSpacer'></div>

                    <hr></hr>
                    <div className='affiliateSpacer'></div>
                    <p className="card-description left"> 
                    Card information
                    </p>
                <div></div>
                    <div className='affiliateSpacer'></div>
                    <div className='card-border'>
                    {/* <form onSubmit={this.handleSubmit}> */}
                    <CardElement className="card-element"/>
                        {/* <button disabled={!this.props.stripe}>Submit</button> */}
                    {/* </form>        */}
                    </div>
                    <div className='confirmedSpacer'></div>
                    {request === "PAYMENT_METHOD_ERROR" &&
                        <p className="errorMessage left top">Failed to process subscription payment</p>
                    }
                    {request === "SERVER_ERROR" && 
                        <p className="errorMessage left top">{errorMessage}</p>
                    }
                    <p className="description-grey left"> 
                            {brand ? (
                                <> 
                                    By confirming your subscription, you allow Uome to set-up future payments. Please note, that you must use an eligible card from {brand.charAt(0).toUpperCase() + brand.slice(1)} for this offer. By continuing you agree to our&nbsp;
                                    <a href="https://www.myuome.com/terms" target="_blank" rel="noopener noreferrer">terms</a>
                                </>
                            ) : (
                                <> 
                                    There will be no charge during your trial period. By confirming your subscription, you allow Uome to set-up future payments once your free trial has ended in accordance with our&nbsp;
                                    <a href="https://www.myuome.com/terms" target="_blank" rel="noopener noreferrer">terms</a>
                                </>
                            )}
                        </p>
                    <p className="description-grey left">You can always manage and cancel your subscription at any time.</p>
                    <div className='affiliateSpacer'></div>
                    <button 
                        type="button" 
                        className="button-full-width" 
                        onClick={this._onSubmit}
                        disabled={!this.props.stripe}
                    >
                        Subscribe</button>
                </div>
                </form>
            </div>
            )
        }
    }
}



export default function AffiliateCheckout(context) {

    const stripePromise = loadStripe(context.stripeKey);

    return (
        <Elements stripe={stripePromise}>        
            <ElementsConsumer>
            {({
                stripe, 
                elements}) => (
                <CheckoutForm 
                    stripe={stripe} 
                    elements={elements}
                    server={context.server}
                    plan={context.plan}
                    token={context.token}
                    planDetails={context.planDetails}
                />
            )}
            </ElementsConsumer>
        </Elements>
      )
};
