export const getCurrencySymbol = (countryCode) => {
    // General currency symbols for a group of countries
    const currencySymbols = {
      EU: '€',  // Euro for European Union countries
      US: '$',  // United States Dollar
      GB: '£',  // British Pound Sterling
      AU: '$',  // Australian Dollar
      CA: '$',  // Canadian Dollar
      JP: '¥',  // Japanese Yen
      CN: '¥',  // Chinese Yuan
      IN: '₹',  // Indian Rupee
      KR: '₩',  // South Korean Won
      MX: '$',  // Mexican Peso
      BR: 'R$', // Brazilian Real
      ZA: 'R',  // South African Rand
      CH: 'CHF',// Swiss Franc
      RU: '₽',  // Russian Ruble
    };
  
    // Specific country codes that use Euro
    const euroCountries = [
      'DE', // Germany
      'FR', // France
      'ES', // Spain
      'IT', // Italy
      'NL', // Netherlands
      'IE', // Ireland
      'BE', // Belgium
      'AT', // Austria
      'PT', // Portugal
      'GR'  // Greece
    ];
  
    // Return the Euro symbol if the country is part of the Eurozone, otherwise use the general mapping
    return euroCountries.includes(countryCode) ? '€' : currencySymbols[countryCode] || '£';
  };
  